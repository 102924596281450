import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col, Form, Table, Navbar, Nav, NavDropdown, NavItem, Spinner, Modal, Tabs, Tab, InputGroup, Card, Alert, Toast, Badge, ProgressBar } from 'react-bootstrap';

import { callServer } from '../utils/APIUtils';
import { doSignOut } from '../store/storeUtils';
import { selectUserCredentials } from '../store/userSlice';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown } from '@fortawesome/fontawesome-free-solid'

import {
	setLogDataAdminActivity,
	setLogDataAdminActivitySortKey,
	setLogDataAdminActivityCalculated,
	setLogDataLogWeb,
	setLogDataLogTransDataEntry,
	getLogDataAdminActivity,
	getLogDataLogWeb,
	getLogDataLogTransDataEntry,
} from '../store/logDataSlice';

function LogsNewByBiller(props)
{
	const [loadingLogs, setLoadingLogs] = useState(true);

	const dispatch = useDispatch();

	let credentials = useSelector(selectUserCredentials);
	let logDataAdminActivity = useSelector(getLogDataAdminActivity);
	
	const calculateAdminActivty = async function()
	{
		let commandGenerate = { 
			command: 'adminactivity-generate',
		};

		let responseObj = await callServer(commandGenerate, credentials);
		//console.log(JSON.stringify(responseObj));
		if (responseObj && responseObj.status === 200)
		{
			dispatch(setLogDataAdminActivityCalculated({}));

			let commandGet = { 
				command: 'table-get',
				arguments: { get: 'adminactivity' }
			};

			responseObj = await callServer(commandGet, credentials);
			//console.log(JSON.stringify(responseObj));
			if (responseObj && responseObj.status === 200 && responseObj.tables && responseObj.tables.length > 0)
			{
				//console.log(JSON.stringify(responseObj.tables[0]));
				dispatch(setLogDataAdminActivity({ table: responseObj.tables[0] }));
			}
			else if (responseObj && responseObj.status === 401)
			{
				doSignOut(dispatch);
			}
		}
		else if (responseObj && responseObj.status === 401)
		{
			doSignOut(dispatch);
		}

		setLoadingLogs(false);
	}

	useEffect(() => {

		if(!logDataAdminActivity.calculated)
		{
			calculateAdminActivty();
		}
		else
		{
			setLoadingLogs(false);
		}
		
		return () => {
		}

	}, []);

	return (
		<div className="admin-tab-body">
			{
				loadingLogs ?
				<span><Spinner size="sm" animation="border" />&nbsp;Loading&hellip;</span>
				:
				<Table striped bordered size="sm">
					<thead>
						<tr>
							<th style={{ width: "30%" }}><a style={{cursor: 'pointer'}} onClick={
								() => {

									let direction = true;
									if (logDataAdminActivity.sortKey === "CompanyNum")
									{
										direction = !logDataAdminActivity.direction;
									}
									dispatch(setLogDataAdminActivitySortKey({ sortKey: "CompanyNum", direction: direction }));
								}
							}>{ logDataAdminActivity.sortKey === "CompanyNum"?<span><FontAwesomeIcon icon={ logDataAdminActivity.direction?faCaretDown:faCaretUp } />&nbsp;</span>:"" }Biller ID</a></th>
							<th style={{ width: "40%" }}><a style={{cursor: 'pointer'}} onClick={
								() => {
									let direction = true;
									if (logDataAdminActivity.sortKey === "LastDate")
									{
										direction = !logDataAdminActivity.direction;
									}
									dispatch(setLogDataAdminActivitySortKey({ sortKey: "LastDate", direction: direction }));
								}
							}>{ logDataAdminActivity.sortKey === "LastDate"?<span><FontAwesomeIcon icon={ logDataAdminActivity.direction?faCaretDown:faCaretUp } />&nbsp;</span>:"" }Last Received Date</a></th>
							<th style={{ width: "30%" }}><a style={{cursor: 'pointer'}} onClick={
								() => {
									let direction = true;
									if (logDataAdminActivity.sortKey === "TotalSignups")
									{
										direction = !logDataAdminActivity.direction;
									}
									dispatch(setLogDataAdminActivitySortKey({ sortKey: "TotalSignups", direction: direction }));
								}
							}>{ logDataAdminActivity.sortKey === "TotalSignups"?<span><FontAwesomeIcon icon={ logDataAdminActivity.direction?faCaretDown:faCaretUp } />&nbsp;</span>:"" }New Signup Count</a></th>
						</tr>
					</thead>
					<tbody>
						{
							logDataAdminActivity.table.map((item) => {
								return <tr key={ item.ID }>
									<td>{ item.CompanyNum }</td>
									<td style={{ fontFamily: "monospace, monospace" }}>{ item.LastDateFormatted }</td>
									<td>{ item.TotalSignups }</td>
								</tr>
							})
						}
					</tbody>
				</Table>
			}
			</div>
	);
}

export default LogsNewByBiller;
