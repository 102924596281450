import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col, Form, Table, Navbar, Nav, NavDropdown, NavItem, Spinner, Modal, Tabs, Tab, InputGroup, Card, Alert, Toast, Badge, ProgressBar, DropdownButton, Dropdown } from 'react-bootstrap';

import { callServer } from '../utils/APIUtils';
import { doSignOut } from '../store/storeUtils';
import { selectUserCredentials, selectUserInfo } from '../store/userSlice';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faUnlock, faTrash, faKey } from '@fortawesome/fontawesome-free-solid'

import {
	getAllUsersWeb,
	getAllUsersWebSorted,
	isUsersWebLoaded,
	getUserWeb,
	setUsersWeb,
	setUserWeb,
} from '../store/usersWebSlice';

const CREATE_MODAL_HIDE = { show: false };
const CREATE_MODAL_SHOW_ADD = { show: true, title: "Add User", isCreate: true };
const CREATE_MODAL_SHOW_EDIT = { show: true, title: "Edit User", isCreate: false };

const CREATE_MODAL_INIT_VALUES = {
	CompanyNum: '', 
	UserName: '', 
	FirstName: '', 
	LastName: '', 
	Address: '', 
	City: '', 
	State: '', 
	Zip: '', 
	Phone: '', 
	EMail: '',
	Access: 'biller',
	Active: true,
	isCreate: true,
	Password: '',
};

function UserView(props)
{
	const [alertInfo, setAlertInfo] = useState(undefined);
	const [modalAlertInfo, setModalAlertInfo] = useState(undefined);
	const [successInfo, setSuccessInfo] = useState(undefined);
	const [createModalInfo, setCreateModalInfo] = useState(CREATE_MODAL_HIDE);
	const [passwordModalInfo, setPasswordModalInfo] = useState({ show: false });
	const [deleteModalInfo, setDeleteModalInfo] = useState({ show: false });
	const [newPassword, setNewPassword] = useState('');
	const [companyList, setCompanyList] = useState([]);

	const [values, setValues] = useState(CREATE_MODAL_INIT_VALUES);
	
	const handleInputChange = e => {
		const {name, value} = e.target
		setValues({...values, [name]: value})
	}

	const addItemToBillers = function(billerId)
	{
		let newBillerIds = values.CompanyNum;
		if (newBillerIds.length > 0)
		{
			newBillerIds += ",";
		}
		newBillerIds += billerId;
		setValues({...values, ["CompanyNum"]: newBillerIds});
	}

	const dispatch = useDispatch();

	let credentials = useSelector(selectUserCredentials);
	let isUsersLoaded = useSelector(isUsersWebLoaded);
	let allUsers = useSelector(getAllUsersWebSorted);
	let userInfo = useSelector(selectUserInfo);

	const fetchAllUsers = async function(userObj)
	{
		let command = { 
			command: 'table-get',
			arguments: { get: "users" },
		};

		setAlertInfo(undefined);

		let responseObj = await callServer(command, credentials);
		//console.log(JSON.stringify(responseObj));
		if (responseObj)
		{
			if (responseObj.status === 200 && responseObj.tables && responseObj.tables.length > 0)
			{
				let users = {};

				let table = responseObj.tables[0];
				for(let d=0;d<table.data.length;d++)
				{
					let data = table.data[d];

					let user = {};
					for(let key in table.fieldMap)
					{
						user[key] = data[table.fieldMap[key]];
					}

					users[user["UserName"]] = user;
				}

				dispatch(setUsersWeb( { users: users } ));
			}
			else if (responseObj.status === 401)
			{
				doSignOut(dispatch);
			}
			else if (responseObj.status >= 400 && responseObj.status < 500)
			{
				setAlertInfo({ heading:"Signup Not Found", body: <p>The signup was not found.</p> });
			}
			else
			{
				setAlertInfo({ heading:"Error Opening Signup", body: <p>Error opening signup: { responseObj.message }</p> });
			}
		}
	}

	useEffect(() => {

		if(!isUsersLoaded)
		{
			fetchAllUsers();
		}
		
		let newCompanyList = [];

		for(let key in userInfo.companyInfo)
		{
			let company = userInfo.companyInfo[key];
			newCompanyList.push(company);
		}

		newCompanyList.sort((a, b) => (a.id > b.id) ? 1 : -1);

		setCompanyList(newCompanyList);

		return () => {
		}

	}, []);

	const doEdit = async function(userObj)
	{
		//console.log("doEdit: "+JSON.stringify(userObj, null, 2));

		let command = { 
			command: 'user-admin-edit',
			operation: userObj.isCreate?"create":"edit",
			username: userObj.UserName,
			newUserInfo: {},
		};

		if (userObj.isCreate || (userObj.Password.trim().length > 0 || userObj.CompanyNum !== userObj.CompanyNumStart))
		{
			command.companyNums = [];
			command.newPassword = userObj.Password.trim();
			
			let parts = userObj.CompanyNum.split(/,/g);
			for(let i=0;i<parts.length;i++)
			{
				let part = parts[i].trim();
				if (part.length <= 0)
				{
					continue;
				}
				command.companyNums.push(part.toUpperCase());
			}
		}

		if (userObj.isCreate)
		{
			command.newUserInfo.UserName = userObj.UserName.trim().toUpperCase();
			command.newUserInfo.Access = userObj.Access.trim();
		}

		command.newUserInfo.FirstName = userObj.FirstName.trim();
		command.newUserInfo.LastName = userObj.LastName.trim();
		command.newUserInfo.Address = userObj.Address.trim();
		command.newUserInfo.City = userObj.City.trim();
		command.newUserInfo.State = userObj.State.trim();
		command.newUserInfo.Zip = userObj.Zip.trim();
		command.newUserInfo.Phone = userObj.Phone.trim();
		command.newUserInfo.EMail = userObj.EMail.trim();
		command.newUserInfo.Active = userObj.Active;

		setAlertInfo(undefined);

		let responseObj = await callServer(command, credentials);
		//console.log(JSON.stringify(responseObj));
		if (responseObj && responseObj.status === 200)
		{
			fetchAllUsers();
		}
		else if (responseObj && responseObj.status === 401)
		{
			doSignOut(dispatch);
		}
		else
		{
			setAlertInfo({ heading:"Error Updating User", body: <p>Error updating user: { userObj.UserName }{ responseObj.message?": "+responseObj.message:"" }</p> });
		}	

		/*
		{
		"CompanyNum": "420",
		"UserName": "RALLEN",
		"FirstName": "Randi",
		"LastName": "Allen",
		"Address": "",
		"City": "",
		"State": "",
		"Zip": "",
		"Phone": "",
		"EMail": "rallen@ci.mansfield.oh.us",
		"Access": "biller",
		"Active": true,
		"isCreate": false,
		"ID": 586,
		"CompanyNumStart": "420"
		}		
		*/
	}

	const doUnLock = async function(username)
	{
		let command = { 
			command: 'user-admin-edit',
			operation: "unlock",
			username: username,
		};

		setAlertInfo(undefined);

		let responseObj = await callServer(command, credentials);
		//console.log(JSON.stringify(responseObj));
		if (responseObj && responseObj.status === 200)
		{
			setSuccessInfo({ show: true, body: <p>User { username } is now unlocked.</p> });

			fetchAllUsers();
		}
		else if (responseObj && responseObj.status === 401)
		{
			doSignOut(dispatch);
		}
		else
		{
			setAlertInfo({ heading:"Error UnLocking User", body: <p>Error unlocking user: { username }{ responseObj.message?": "+responseObj.message:"" }</p> });
		}	
	}

	const doDelete = async function(username)
	{
		let command = { 
			command: 'user-admin-edit',
			operation: "delete",
			username: username,
		};

		setAlertInfo(undefined);

		let responseObj = await callServer(command, credentials);
		//console.log(JSON.stringify(responseObj));
		if (responseObj && responseObj.status === 200)
		{
			setSuccessInfo({ show: true, body: <p>User { username } account has been deleted.</p> });

			fetchAllUsers();
		}
		else if (responseObj && responseObj.status === 401)
		{
			doSignOut(dispatch);
		}
		else
		{
			setAlertInfo({ heading:"Error Deleting User", body: <p>Error deleting user: { username }{ responseObj.message?": "+responseObj.message:"" }</p> });
		}	
	}

	const doChangePassword = async function(username, newPassword)
	{
		if (newPassword.trim().length > 0)
		{
			let command = { 
				command: 'user-admin-edit',
				operation: "changepassword",
				username: username,
				newPassword: newPassword.trim(),
			};

			setAlertInfo(undefined);
	
			let responseObj = await callServer(command, credentials);
			//console.log(JSON.stringify(responseObj));
			if (responseObj && responseObj.status === 200)
			{
				setSuccessInfo({ show: true, body: <p>User { username } password has been updated.</p> });

				fetchAllUsers();
			}
			else if (responseObj && responseObj.status === 401)
			{
				doSignOut(dispatch);
			}
			else
			{
				setAlertInfo({ heading:"Error Changing Password", body: <p>Error changing password for user: { username }{ responseObj.message?": "+responseObj.message:"" }</p> });
			}	
		}
	}

	return (
		<div className="admin-tab-body">
			{ alertInfo &&
				<Alert variant="danger" onClose={() => setAlertInfo(undefined) } dismissible><Alert.Heading>{ alertInfo.heading }</Alert.Heading>{ alertInfo.body }</Alert>
			}
			{ successInfo &&
				<Toast style={{
					position: 'sticky',
					top: 0,
					left: 0
				  }} delay={3000} autohide onClose={() => setSuccessInfo(undefined) }><Toast.Body>{ successInfo.body }</Toast.Body></Toast>
			}
			<Modal show={ deleteModalInfo.show } onHide={ () => { setDeleteModalInfo({ show: false }) } }>
				<Modal.Header closeButton>
					<Modal.Title>{ deleteModalInfo.title }</Modal.Title>
				</Modal.Header>
				<Modal.Body>{ deleteModalInfo.body }</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={ () => { setDeleteModalInfo({ show: false }) } }>
					No
					</Button>
					<Button variant="primary" onClick={ () => {
						doDelete(deleteModalInfo.username); 
						setDeleteModalInfo({ show: false });
					} }>Delete User</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={ passwordModalInfo.show } onHide={ () => { setPasswordModalInfo({ show: false }) } }>
				<Modal.Header closeButton>
					<Modal.Title>Change Password</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				<Form.Group as={ Row }>
						<Form.Label column sm="4">New Password</Form.Label>
						<Col sm="8">
							<Form.Control 
								type="text" 
								name="NewPassword"
								onChange={ (e) => { setNewPassword(e.target.value) } }
								value={ newPassword }
								/>
						</Col>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={ () => { setPasswordModalInfo({ show: false }) } }>
					Close
					</Button>
					<Button variant="primary" onClick={ () => {
						doChangePassword(passwordModalInfo.username, newPassword); 
						setPasswordModalInfo({ show: false });
					} }>Change Password</Button>
				</Modal.Footer>
			</Modal>
			<Modal size="lg" show={ createModalInfo.show } onHide={ () => { setCreateModalInfo(CREATE_MODAL_HIDE) } }>
				<Modal.Header closeButton>
					<Modal.Title>{ createModalInfo.title }</Modal.Title>
				</Modal.Header>
				<Modal.Body>

					{ modalAlertInfo &&
						<Alert variant="danger" onClose={() => setModalAlertInfo(undefined) } dismissible>{ modalAlertInfo.body }</Alert>
					}

					<Form.Group as={ Row }>
						<Form.Label column sm="4">User Name</Form.Label>
						<Col sm="8">
							{
								values.isCreate?
								<Form.Control 
								type="text" 
								name="UserName"
								onChange={ handleInputChange }
								value={ values.UserName }
								/>
								:
								<strong>{ values.UserName }</strong>
							}
						</Col>
					</Form.Group>
					<Form.Group as={ Row }>
						<Form.Label column sm="4">First Name</Form.Label>
						<Col sm="8">
							<Form.Control 
								type="text" 
								name="FirstName"
								onChange={ handleInputChange }
								value={ values.FirstName }
								/>
						</Col>
					</Form.Group>
					<Form.Group as={ Row }>
						<Form.Label column sm="4">Last Name</Form.Label>
						<Col sm="8">
							<Form.Control 
								type="text" 
								name="LastName"
								onChange={ handleInputChange }
								value={ values.LastName }
								/>
						</Col>
					</Form.Group>
					<Form.Group as={ Row }>
						<Form.Label column sm="4">Access Type</Form.Label>
						<Col sm="8">
							{
								values.isCreate?
								<Form.Control 
									as="select"
									name="Access"
									onChange={ handleInputChange }
									value={ values.Access }
									>
									<option key="biller" value="biller">biller</option>
									<option key="admin" value="admin">admin</option>
									<option key="dataentry" value="dataentry">dataentry</option>
								</Form.Control>
								:
								<strong>{ values.Access }</strong>
							}
						</Col>
					</Form.Group>
					<Form.Group as={ Row }>
						<Form.Label column sm="4">Biller IDs</Form.Label>
						<Col sm="8">
							<InputGroup className="mb-3">
								<Form.Control 
									type="text" 
									name="CompanyNum"
									onChange={ handleInputChange }
									value={ values.CompanyNum }
									/>
								<DropdownButton
									as={ InputGroup.Append }
									className="biller-dropdown-menu"
									variant="outline-secondary"
									menuAlign="right"
									title=""
									id="input-group-dropdown-2"
									>
									{
										companyList.map((item, index) => {
											return <Dropdown.Item onClick={ () => {
												addItemToBillers(item.id);
											} }>{ item.id + " - " + item.name }</Dropdown.Item>
										})
									}
								</DropdownButton>
							</InputGroup>
								<p>(For <strong>billers</strong> this should be the <strong>COMPANY NUM</strong> field in the <strong>COMPANY</strong> table. For <strong>admin</strong> and <strong>dataentry</strong> this should be the dataentry <strong>Operator ID</strong>. For multiple biller access, separate with commas.) Example: CA302,MI302,WI302)</p>
						</Col>
					</Form.Group>
					<Form.Group as={ Row }>
						<Form.Label column sm="4">EMail</Form.Label>
						<Col sm="8">
							<Form.Control 
								type="text" 
								name="EMail"
								onChange={ handleInputChange }
								value={ values.EMail }
								/>
						</Col>
					</Form.Group>
					<Form.Group as={ Row }>
						<Form.Label column sm="4">Address</Form.Label>
						<Col sm="8">
							<Form.Control 
								type="text" 
								name="Address"
								onChange={ handleInputChange }
								value={ values.Address }
								/>
						</Col>
					</Form.Group>
					<Form.Group as={ Row }>
						<Form.Label column sm="4">City</Form.Label>
						<Col sm="8">
							<Form.Control 
								type="text" 
								name="City"
								onChange={ handleInputChange }
								value={ values.City }
								/>
						</Col>
					</Form.Group>
					<Form.Group as={ Row }>
						<Form.Label column sm="4">State</Form.Label>
						<Col sm="8">
							<Form.Control 
								type="text" 
								name="State"
								onChange={ handleInputChange }
								value={ values.State }
								/>
						</Col>
					</Form.Group>
					<Form.Group as={ Row }>
						<Form.Label column sm="4">Zip</Form.Label>
						<Col sm="8">
							<Form.Control 
								type="text" 
								name="Zip"
								onChange={ handleInputChange }
								value={ values.Zip }
								/>
						</Col>
					</Form.Group>
					<Form.Group as={ Row }>
						<Form.Label column sm="4">Phone</Form.Label>
						<Col sm="8">
							<Form.Control 
								type="text" 
								name="Phone"
								onChange={ handleInputChange }
								value={ values.Phone }
								/>
						</Col>
					</Form.Group>
					<Form.Group as={ Row }>
						<Form.Label column sm="4">Password</Form.Label>
						<Col sm="8">
							<Form.Control 
								type="text" 
								name="Password"
								onChange={ handleInputChange }
								value={ values.Password }
								/>
						</Col>
					</Form.Group>
					<Form.Group as={ Row }>
						<Form.Label column sm="4">&nbsp;</Form.Label>
						<Col sm="8">
							<Form.Check 
								type="checkbox" 
								name="Active"
								onChange={e => {
									setValues({...values, Active: e.target.checked})
								}}
								checked={ values.Active }
								label="Active"
								/>
						</Col>
					</Form.Group>

				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={ () => { setCreateModalInfo(CREATE_MODAL_HIDE) } }>
					Close
					</Button>
					<Button variant="primary" onClick={ () => {

						let alertErrors = [];

						if (values.FirstName.trim().length <= 0 && values.LastName.trim().length <= 0)
						{
							alertErrors.push(<li>Either <strong>First Name</strong> or <strong>Last Name</strong> field is required.</li>);
						}
						if (values.CompanyNum.trim().length <= 0)
						{
							alertErrors.push(<li>The <strong>Biller ID</strong> field is required.</li>);
						}

						if (values.isCreate)
						{
							if (values.UserName.trim().length <= 0)
							{
								alertErrors.push(<li>The <strong>User Name</strong> field is required.</li>);
							}
							if (values.Password.trim().length <= 0)
							{
								alertErrors.push(<li>The <strong>Password</strong> field is required.</li>);
							}
						}
						else
						{
							if (values.CompanyNumStart !== values.CompanyNum && values.Password.trim().length <= 0)
							{
								alertErrors.push(<li>The <strong>Password</strong> field is required when changing the <strong>Biller ID</strong> field.</li>);
							}
						}

						if (alertErrors.length <= 0)
						{
							doEdit(values); 
							setCreateModalInfo(CREATE_MODAL_HIDE);
						}
						else
						{
							setModalAlertInfo({ body: <p>Errors:<ul>{ alertErrors }</ul></p> });
						}
					} }>
					{ createModalInfo.title }
					</Button>
				</Modal.Footer>
			</Modal>
			<Button onClick={ () => { 

				setModalAlertInfo(undefined);
				setValues(CREATE_MODAL_INIT_VALUES); 
				setCreateModalInfo(CREATE_MODAL_SHOW_ADD);
				
				} }>Add User</Button>
			<div className="admin-rawsignups-spacer"></div>
			{ allUsers.length <= 0 ?
				<span><Spinner size="sm" animation="border" />&nbsp;Loading&hellip;</span>
				:
				<Table striped bordered size="sm">
					<thead>
						<tr>
							<th>User</th>
							<th>Access</th>
							<th>Biller IDs</th>
							<th style={{ whiteSpace: "nowrap" }}>Actions</th>
						</tr>
					</thead>
					<tbody>
						{ allUsers.map((item) => {

							let companyNums = item.CompanyNum;

							companyNums = companyNums.replace(/\|/g, ", ").trim();
							if (companyNums.startsWith(","))
							{
								companyNums = companyNums.substr(1);
							}
							
							if (companyNums.endsWith(","))
							{
								companyNums = companyNums.substr(0, companyNums.length-1);
							}

							return <tr key={ item.UserName }>
								<td>{ item.LastName + ", " + item.FirstName }<br/><small><i>{ item.UserName }</i></small></td>
								<td>{ item.Access }</td>
								<td>{ companyNums }</td>
								<td style={{ whiteSpace: "nowrap" }}>
									<Button variant="outline-primary" title="Edit" onClick={
										() => {

											let editItem = { ...CREATE_MODAL_INIT_VALUES, ...item };

											editItem.CompanyNum = editItem.CompanyNum.replace(/\|/g, ",").trim();
											if (editItem.CompanyNum.startsWith(","))
											{
												editItem.CompanyNum = editItem.CompanyNum.substr(1);
											}
											if (editItem.CompanyNum.endsWith(","))
											{
												editItem.CompanyNum = editItem.CompanyNum.substr(0, editItem.CompanyNum.length-1);
											}

											editItem.CompanyNumStart = editItem.CompanyNum;
											editItem.isCreate = false;

											setValues(editItem); 
											setModalAlertInfo(undefined);
											setCreateModalInfo(CREATE_MODAL_SHOW_EDIT);						
										}
									}><FontAwesomeIcon icon={ faEdit } /></Button>&nbsp;
									<Button variant="outline-primary" title="Change Password"onClick={
										() => {
											setNewPassword('');
											setPasswordModalInfo({ show: true, username: item.UserName });
										}
									}><FontAwesomeIcon icon={ faKey } /></Button>&nbsp;
									<Button variant="outline-primary" title="UnLock" onClick={
										() => {
											doUnLock(item.UserName);
										}
									}><FontAwesomeIcon icon={ faUnlock } /></Button>&nbsp;
									<Button variant="outline-primary" title="Delete" onClick={
										() => {

											setDeleteModalInfo({ show: true, username: item.UserName, title: <p>Delete User { item.UserName }</p>, body: <p>Are you sure you want to delete user { item.UserName }?</p> });
										}
									}><FontAwesomeIcon icon={ faTrash } /></Button>&nbsp;
								</td>
							</tr>
						})
						}
					</tbody>
				</Table>
			}
		</div>
	);
}

export default UserView;
