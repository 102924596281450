import React, { useState, useRef, useEffect } from 'react';

import { Alert, Button, Row, Col, Form, Card, Spinner, InputGroup } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faCheckCircle, faTimesCircle, faCircle } from '@fortawesome/fontawesome-free-solid'

const LANGUAGE = 'en-US';

function ChangePassword({ badWords, onChange })
{
	const [userPasswordNew1, setUserPasswordNew1] = useState('');
	const [userPasswordNew2, setUserPasswordNew2] = useState('');
	const [showPasswordNew, setShowPasswordNew] = useState(false);

	let userPasswordNew1Lower = userPasswordNew1.trim().toLocaleLowerCase(LANGUAGE);

	let badWordsExist = false;
	for(let i=0;i<badWords.length;i++)
	{
		if (userPasswordNew1Lower.indexOf(badWords[i].toLocaleLowerCase(LANGUAGE)) >= 0)
		{
			badWordsExist = true;
			break;
		}
	}

	let min8Chars = (userPasswordNew1.trim().length >= 8)?<FontAwesomeIcon className="signin-newpassword-good" icon={ faCheckCircle } />:<FontAwesomeIcon className="signin-newpassword-bad" icon={ faTimesCircle } />;
	let upperChars = (userPasswordNew1.match(/[A-Z]/))?<FontAwesomeIcon className="signin-newpassword-good" icon={ faCheckCircle } />:<FontAwesomeIcon className="signin-newpassword-bad" icon={ faTimesCircle } />;
	let lowerChars = (userPasswordNew1.match(/[a-z]/))?<FontAwesomeIcon className="signin-newpassword-good" icon={ faCheckCircle } />:<FontAwesomeIcon className="signin-newpassword-bad" icon={ faTimesCircle } />;
	let numberChars = (userPasswordNew1.match(/[0-9]/))?<FontAwesomeIcon className="signin-newpassword-good" icon={ faCheckCircle } />:<FontAwesomeIcon className="signin-newpassword-bad" icon={ faTimesCircle } />;
	let specialChars = (userPasswordNew1.match(/[~!@#$%\^&*_\-+=|\\/(){}[\]:;"'<>,.?]/))?<FontAwesomeIcon className="signin-newpassword-good" icon={ faCheckCircle } />:<FontAwesomeIcon className="signin-newpassword-bad" icon={ faTimesCircle } />;
	let badWordsChars = (!badWordsExist)?<FontAwesomeIcon className="signin-newpassword-good" icon={ faCheckCircle } />:<FontAwesomeIcon className="signin-newpassword-good" className="" className="signin-newpassword-bad" icon={ faTimesCircle } />;

	let isValid = true;

	if (!min8Chars || !upperChars || !lowerChars || !numberChars || !specialChars || !badWordsChars)
	{
		isValid = false;
	}

	if (userPasswordNew1 !== userPasswordNew2)
	{
		isValid = false;
	}

    useEffect( () => {
		
		if (onChange)
		{
			onChange(userPasswordNew1, isValid );
		}

    }, [userPasswordNew1, userPasswordNew2]);

	return (
		<>
			<div className="signin-changepassword-spacer"></div>
			<p>
				<span className="signin-newpassword-title">Password Rules</span>
				<ul style={{ listStyleType: "none" }}>
					<li>{ min8Chars }&nbsp;Minimum 8 Characters Long</li>
					<li>{ upperChars }&nbsp;Uppercase Letter (A-Z)</li>
					<li>{ lowerChars }&nbsp;Lowercase Letter (a-z)</li>
					<li>{ numberChars }&nbsp;Number (0-9)</li>
					<li>{ specialChars }&nbsp;Special Character ({ "~!@#$%^&*_-+=|\/(){}[]:;\"'<>,.?" })</li>
					<li>{ badWordsChars }&nbsp;Cannot Contain User Name</li>
					<li><FontAwesomeIcon className="signin-newpassword-good" icon={ faCircle } />&nbsp;Cannot Re-Use Last 5 Passwords</li>
				</ul>
			</p>
			<Form.Group controlId="userPassword">
				<Form.Label className="credentials-label">New Password</Form.Label>
				<InputGroup>
					<Form.Control 
						type={ showPasswordNew?"text":"password" }
						name="userPasswordNew1"
						onChange={ (evt) => { setUserPasswordNew1(evt.target.value) } }
						value={ userPasswordNew1 }
						/>
						<InputGroup.Append>
						<Button variant="primary-outline" tabIndex="-1" onClick={
							() => {
								setShowPasswordNew(!showPasswordNew);
							}
						}><FontAwesomeIcon icon={ showPasswordNew?faEyeSlash:faEye } /></Button>
					</InputGroup.Append>
				</InputGroup>
			</Form.Group>
			<Form.Group controlId="userPassword">
				<Form.Label className="credentials-label">Verify New Password</Form.Label>
				<InputGroup>
					<Form.Control 
						type={ showPasswordNew?"text":"password" }
						name="userPasswordNew2"
						onChange={ (evt) => { setUserPasswordNew2(evt.target.value) } }
						value={ userPasswordNew2 }
						/>
				</InputGroup>
			</Form.Group>
		</>
	);
}

export default ChangePassword;
