import { configureStore } from '@reduxjs/toolkit';
import userSlice from './userSlice';
import changePasswordSlice from './changePasswordSlice';
import rawSignupsSlice from './rawSignupsSlice';
import processedSignupsSlice from './processedSignupsSlice';
import usersWebSlice from './usersWebSlice';
import logDataSlice from './logDataSlice';

export default configureStore({
	reducer: {
		user: userSlice,
		changePassword: changePasswordSlice,
		rawSignups: rawSignupsSlice,
		processedSignups: processedSignupsSlice,
		usersWeb: usersWebSlice,
		logData: logDataSlice,
	},
});
