import { createSlice } from '@reduxjs/toolkit';

const INIT_DATA = {
	users: {},
	usersSorted: [],
	loaded: false,
};

export const usersWebSlice = createSlice({
	name: 'usersWeb',
	initialState: INIT_DATA,
	reducers: {
		clearUsers: (state, action) => {
			for(let key in INIT_DATA)
			{
				state[key] = INIT_DATA[key];
			}
		},
		setUsersWeb: (state, action) => {
            state.users = action.payload.users;
			
			let allUsers = [];
			for(let key in state.users)
			{
				allUsers.push(state.users[key]);
			}
		
			allUsers.sort((a, b) => (a.LastName > b.LastName) ? 1 : -1)
			state.usersSorted = allUsers;

			state.loaded = true;
		},
		setUserWeb: (state, action) => {
            state.users[action.payload.user["UserName"]] = action.payload.user;
			
			let allUsers = [];
			for(let key in state.users)
			{
				allUsers.push(state.users[key]);
			}
		
			allUsers.sort((a, b) => (a.LastName > b.LastName) ? 1 : -1)
			
			state.usersSorted = allUsers;
		},
	},
});

export const getAllUsersWeb = (state) => {
	return state.usersWeb.users;
}

export const getAllUsersWebSorted = (state) => {
	return state.usersWeb.usersSorted;
}

export const getUserWeb = (state, userName) => {
	return state.usersWeb.users[userName];
}

export const isUsersWebLoaded = (state) => {
	return state.usersWeb.loaded;
}

export const { 
	clearUsers,
	setUsersWeb,
	setUserWeb,
} = usersWebSlice.actions;

export default usersWebSlice.reducer;
