import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';

import { Button, Row, Col, Form, Table, Navbar, Nav, NavDropdown, NavItem, Spinner, Modal, Tabs, Tab, InputGroup, Card, Alert, Badge } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo, faEye, faEyeSlash } from '@fortawesome/fontawesome-free-solid'

import { selectUserCredentials } from '../store/userSlice';
import { callServer } from '../utils/StopWebUtils';

const SUCCESSMODAL_HIDE = { showing: false };

function getResponseErrorMessage(response)
{
	let message = "Unknown error";
	if (response)
	{
		if (response.response && response.response.message)
			message = response.response.message;
		else if (response.message)
			message = response.message;
	}
	
	return message;
}

function StopWebView(props)
{
	const [tryToConnect, setTryToConnect] = useState(true);
	const [tryDBSignIn, setTryDBSignIn] = useState(false);
	const [connected, setConnected] = useState(false);
	const [dbSignedIn, setDBSignedIn] = useState(false);
	const [dbPassword, setDBPassword] = useState(undefined);
	const [stats, setStats] = useState(undefined);
	const [doSyncTable, setDoSyncTable] = useState(undefined);
	const [doGetSignups, setDoGetSignups] = useState(false);
	const [successModal, setSuccessModal] = useState(SUCCESSMODAL_HIDE);
	const [showPassword, setShowPassword] = useState(false);

	let credentials = useSelector(selectUserCredentials);

	credentials.dbPassword = dbPassword;

	let url = window.runConfig.stopWebAPIURL;

	const WORKING_SPINNER = <span>&nbsp;&nbsp;<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;&nbsp;</span>;

	const doDisconnect = function()
	{
		setConnected(false);
		setDBSignedIn(false);
		setStats(undefined);
	}

	const updateStats = async function()
	{
		let responseObj = await callServer(url, { command: "get-statistics", credentials: credentials });
		if (responseObj && responseObj.status === 200 && responseObj.response && responseObj.response.statistics)
		{
			let newStats = {};

			for(let i=0;i<responseObj.response.statistics.length;i++)
			{
				newStats[responseObj.response.statistics[i].name] = responseObj.response.statistics[i];
			}

			//console.log(JSON.stringify(newStats, null, 2));

			setStats(newStats);
		}
		else
		{
			setStats(undefined);
		}
	}

	const pingAPI = async function()
	{
		setSuccessModal(SUCCESSMODAL_HIDE);

		let responseObj = await callServer(url, { command: "ping", credentials: credentials });
		if (responseObj && responseObj.status === 200)
		{
			setConnected(true);
		}
		else
		{
			doDisconnect();
		}

		setTryToConnect(false);
	}

	const signInDB = async function()
	{
		setSuccessModal(SUCCESSMODAL_HIDE);

		let responseObj = await callServer(url, { command: "signin-database", credentials: credentials });
		if (responseObj && responseObj.status === 200)
		{
			await updateStats();
			setDBSignedIn(true);
		}
		else
		{
			setDBSignedIn(false);
		}

		setTryDBSignIn(false);
	}

	const getSignups = async function()
	{
		setSuccessModal(SUCCESSMODAL_HIDE);

		let responseObj = await callServer(url, { command: "sync-signups", credentials: credentials, arguments: { get: 'headers-details' } });
		
		//console.log(JSON.stringify(responseObj, null, 2));

		let modalInfo = { showing: true, title: "Get New Headers and Details", body: "Success" };

		if (responseObj && responseObj.status === 200)
		{
			let headerCount = 0;
			let detailCount = 0;
			if (responseObj && responseObj.response && responseObj.response.tablesUpdates && responseObj.response.tablesUpdates.length > 0)
			{
				for(let i=0;i<responseObj.response.tablesUpdates.length;i++)
				{
					let table = responseObj.response.tablesUpdates[i];

					switch(table.name)
					{
					case "headers":
						headerCount = table.count;
						break;
					case "details":
						detailCount = table.count;
						break;
					}

				}
			}

			modalInfo.body = <p>{ headerCount } headers and { detailCount } details were successfully downloaded</p>
		}
		else
		{
			modalInfo.body = <Alert variant="danger">{ getResponseErrorMessage(responseObj) }</Alert>
		}

		await updateStats();

		setSuccessModal(modalInfo);

		setDoGetSignups(false);
	}
	
	useEffect(() => {

		if (doGetSignups)
		{
			getSignups();
		}

		return () => {
		}

	}, [doGetSignups]);

	const isSyncTableWorking = function(tableName)
	{
		return (doSyncTable && doSyncTable.tableName === tableName);
	}
	
	const syncTable = async function(tableInfo)
	{
		setSuccessModal(SUCCESSMODAL_HIDE);

		let responseObj = await callServer(url, { command: "sync-table", credentials: credentials, arguments: tableInfo });

		//console.log(JSON.stringify(responseObj, null, 2));

		let modalInfo = { showing: true, title: tableInfo.title, body: "Success" };

		if (responseObj && responseObj.status === 200)
		{
			if (responseObj && responseObj.response && responseObj.response.tables && responseObj.response.tables.length > 0)
			{
				let tableInfo = [];
				for(let i=0;i<responseObj.response.tables.length;i++)
				{
					let table = responseObj.response.tables[i];

					tableInfo.push(<li><strong>{ table.name }</strong> with { table.rowsAdded } records</li>);
				}

				modalInfo.body = <><p>{ responseObj.response.tables.length>1?"The following tables were uploaded":"The following table was uploaded" }</p><ul>{ tableInfo }</ul></>
			}
		}
		else
		{
			modalInfo.body = <Alert variant="danger">{ getResponseErrorMessage(responseObj) }</Alert>
		}

		await updateStats();

		setSuccessModal(modalInfo);

		setDoSyncTable(undefined);
	}

	useEffect(() => {

		if (doSyncTable)
		{
			syncTable(doSyncTable);
		}

		return () => {
		}

	}, [doSyncTable]);


	useEffect(() => {

		if (tryToConnect)
		{
			setTimeout(pingAPI, 200);
		}

		return () => {
		}

	}, [tryToConnect]);

	useEffect(() => {

		if (tryDBSignIn)
		{
			setTimeout(signInDB, 200);
		}

		return () => {
		}

	}, [tryDBSignIn]);

	useHotkeys('enter', () => setTryDBSignIn(true), {enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT']});

	let getSignupsActive = (stats && stats.signups && stats.signups.newHeaders > 0);

	return (
		<div className="admin-tab-body">
			<Modal size="lg" show={ successModal.showing } onHide={() => setSuccessModal(SUCCESSMODAL_HIDE)}>
				<Modal.Header closeButton={ true }>
					<Modal.Title>{ successModal.title }</Modal.Title>
				</Modal.Header>
				<Modal.Body>{ successModal.body }</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setSuccessModal(SUCCESSMODAL_HIDE)}>Close</Button>
				</Modal.Footer>
			</Modal>
			{ connected ?
				<>
				{
					dbSignedIn ?
					<>
					<div>
						<Row>
							<Col xs="2"></Col>
							<Col className="signin-col" xs="8">
								<Card className="signin-card">
									<Card.Header>Download New Signups</Card.Header>
									<Card.Body>
										<Button disabled={ !getSignupsActive } onClick={ () => { setDoGetSignups(true) } } className="stopdbsync-buttons">
											<span>Download New Headers and Details</span>
											{ stats &&
											<span>&nbsp;&nbsp;<Badge variant="light">{ stats.signups.newHeaders + " / " + stats.signups.newDetails }</Badge></span>
											}
											{ doGetSignups && 
												<>{ WORKING_SPINNER }</>					
											}
										</Button>
									</Card.Body>
								</Card>
							</Col>
							<Col xs="2"></Col>
						</Row>
						<br/>
						<Row>
							<Col xs="2"></Col>
							<Col className="signin-col" xs="8">
								<Card className="signin-card">
									<Card.Header>Upload Tables</Card.Header>
									<Card.Body>
										<Row>
											<Col>
												<Button onClick={ () => { setDoSyncTable({ tableName: 'company', title: "Upload Company" }) } } className="stopdbsync-buttons">
													<span>Company</span>
													<span>
														{ isSyncTableWorking('company') && 
															<>{ WORKING_SPINNER }</>					
														}
													</span>
												</Button>
											</Col>
											<Col>
												<Button onClick={ () => { setDoSyncTable({ tableName: 'formssource', title: "Upload Forms Source" }) } } className="stopdbsync-buttons">
													<span>Forms Source</span>
													<span>
														{ isSyncTableWorking('formssource') && 
															<>{ WORKING_SPINNER }</>					
														}
													</span>
												</Button>
											</Col>
										</Row>
										<div className="stopdbsync-button-row-spacer"></div>
										<Row>
											<Col>
												<Button onClick={ () => { setDoSyncTable({ tableName: 'zipcodes', title: "Upload Zip Codes" }) } } className="stopdbsync-buttons">
													<span>Zip Codes</span>
													<span>
														{ isSyncTableWorking('zipcodes') && 
															<>{ WORKING_SPINNER }</>					
														}
													</span>
												</Button>
											</Col>
											<Col>
												<Button onClick={ () => { setDoSyncTable({ tableName: 'billeracctnumformats', title: "Upload Biller Acct Formats" }) } } className="stopdbsync-buttons">
													<span>Biller Acct Formats</span>
													<span>
														{ isSyncTableWorking('billeracctnumformats') && 
															<>{ WORKING_SPINNER }</>					
														}
													</span>
												</Button>
											</Col>
										</Row>
										<div className="stopdbsync-button-row-spacer"></div>
										<Row>
											<Col>
												<Button onClick={ () => { setDoSyncTable({ tableName: 'finames', title: "Upload FI Names (R/T)" }) } } className="stopdbsync-buttons">
													<span>FI Names (R/T)</span>
													<span>
														{ isSyncTableWorking('finames') && 
															<>{ WORKING_SPINNER }</>					
														}
													</span>
												</Button>
											</Col>
											<Col>
												<Button onClick={ () => { setDoSyncTable({ tableName: 'errorcodes', title: "Upload Error Codes" }) } } className="stopdbsync-buttons">
													<span>Error Codes</span>
													<span>
														{ isSyncTableWorking('errorcodes') && 
															<>{ WORKING_SPINNER }</>					
														}
													</span>
												</Button>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Col>
							<Col xs="2"></Col>
						</Row>
					</div>
					</>
					:
					<>
						<Row>
							<Col xs="2"></Col>
							<Col className="signin-col" xs="8">
								<Card className="signin-card">
									<Card.Header>Sign In To StopDB</Card.Header>
									<Card.Body>
									<Form.Group controlId="userUsername">
										<Form.Label className="credentials-label">StopDB Password</Form.Label>
										<InputGroup>
											<Form.Control 
												type={ showPassword?"text":"password" }
												name="userPassword"
												onChange={ (evt) => { setDBPassword(evt.target.value) } }
												value={ dbPassword }
												/>
											<InputGroup.Append>
												<Button variant="primary-outline" tabIndex="-1" onClick={
													() => {
														setShowPassword(!showPassword);
													}
												}><FontAwesomeIcon icon={ showPassword?faEyeSlash:faEye } /></Button>
											</InputGroup.Append>
										</InputGroup>
									</Form.Group>
									<div className="signin-button text-right">
										<Button onClick={ () => { setTryDBSignIn(true) } }>
										<span>Sign In</span>
										<span>
											{ tryDBSignIn && 
												<>{ WORKING_SPINNER }</>					
											}
										</span>

										</Button>
									</div>
									</Card.Body>
								</Card>
							</Col>
							<Col xs="2"></Col>
						</Row>
					</>
				}
				</>
				:
				<>
				<Row className="align-items-baseline">
					<Col xs="auto"><Button onClick={ ()=> { setTryToConnect(true) } }><FontAwesomeIcon size="sm" icon={ faRedo } />&nbsp;Retry</Button></Col>
					<Col>
						<Alert variant="info">
							{ tryToConnect ?
								<><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;Connecting&hellip;</>
								:
								<span>StopDB Sync Server is not available.</span>
							}
						</Alert>
					</Col>
				</Row>
				</>
			}
		</div>
	);
}

export default StopWebView;
