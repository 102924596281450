const crypto = require('crypto');
const fetch = require('node-fetch');

const util = require('util');
const zlib = require('zlib');
const deflate = util.promisify(zlib.deflate);

export async function loadLocalFile(path)
{
	let ret = undefined;

	try
	{
		let response = await fetch(path);
		if (response)
		{
			ret = response.text();
		}
	}
	catch(err)
	{
		console.log("loadLocalFile["+path+"]: "+err.toString());
	}

	return ret;
}

export async function callServer(data, credentials)
{
	let responseObj = { status: 500 };

	let url = window.runConfig.apiBaseURL;
	let path = window.runConfig.apiBasePath + data.command;

	try
	{
		let allDataStr = JSON.stringify(data);
		let allDataBuffer = await deflate(allDataStr);

		//console.log("callServer ["+data.command+"]: content-length: "+allDataBuffer.length);

		let headers = {
			'Content-Type': 'application/json',
			'Content-Encoding': 'deflate',
		};

		if (credentials.jwt)
		{
			headers.Authorization = 'Bearer '+credentials.jwt;
		}
		else if (credentials.username && credentials.password)
		{
			headers.Authorization = 'Basic '+Buffer.from(credentials.username+":"+credentials.password).toString('base64');
		}
		
		let response = await fetch(url+path, {
			method: 'post',
			compress: true,
			headers: headers,
			body: allDataBuffer
		});
		
		responseObj = await response.json();
	}
	catch(err)
	{
		console.error("syncSignups[getData]: "+err.toString());
		console.error(err.stack);

		if (err.code === "ECONNREFUSED")
			responseObj.message = "Remote API connection error (ECONNREFUSED). API server is most likely down. Try again later: url: "+url+path;
		else
			responseObj.message = err.toString();
	}
	
	return responseObj;
}
