import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';

import { Alert, Button, Row, Col, Form, Card, Spinner, InputGroup } from 'react-bootstrap';

import { callServer } from '../utils/APIUtils';
import { doSignOut, doClearStore } from '../store/storeUtils';
import ChangePassword from '../controls/ChangePassword';
import { useCookies } from 'react-cookie';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faCheckCircle, faTimesCircle, faCircle } from '@fortawesome/fontawesome-free-solid'

import {
	setUser,
	setUserInfo,
	signOut,
} from '../store/userSlice';

const LANGUAGE = 'en-US';

function SignIn(props)
{
	const [cookies, setCookie] = useCookies(['signinShowEditMode']);

	const [userUsername, setUserUsername] = useState('');
	const [userPassword, setUserPassword] = useState('');
	const [signinError, setSigninError] = useState(undefined);
	const [showSpinner, setShowSpinner] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [signInEffect, setSignInEffect] = useState(false);
	const [showChangePassword, setShowChangePassword] = useState(false);
	const [userPasswordNew, setUserPasswordNew] = useState('');
	const [userPasswordNewValid, setUserPasswordNewValid] = useState(false);

	const dispatch = useDispatch();

	const doSignInRemote = async function()
	{
		let signInSuccessful = false;
		let signinCommand = { command: 'user-signin' };
				
		if (showChangePassword)
		{
			signinCommand.newPassword = userPasswordNew;
		}
		
		let userUsernameUpper = userUsername.toLocaleUpperCase(LANGUAGE);
		let credentials = { username: userUsernameUpper, password: userPassword };
		
		let signinResponse = await callServer(signinCommand, credentials);

		if (signinResponse.status === 200)
		{
			credentials = {
				jwt: signinResponse.jwt
			};

			doClearStore(dispatch);

			dispatch(setUser({ username: userUsernameUpper, jwt: signinResponse.jwt, userInfo: signinResponse.userInfo }));

			signInSuccessful = true;
		}
		else if (signinResponse.status == 418)
		{
			setSigninError("Your password has expired, please enter a new password.");
			setShowChangePassword(true);	
		}
		else if (signinResponse.status == 419)
		{
			setSigninError("Your new password does not conform to the password rules.");
			setShowChangePassword(true);	
		}
		else if (signinResponse.status == 420)
		{
			setSigninError("Your new password has been recently used.");
			setShowChangePassword(true);	
		}
		else if (signinResponse.status == 421)
		{
			setSigninError("Error updating password, try again later.");
			setShowChangePassword(true);	
		}
		else if (signinResponse.status == 422)
		{
			let hours = Math.floor(signinResponse.lockTimeRemaining/60);
			let minutes = signinResponse.lockTimeRemaining%60;

			let timeStr = '';
			if (hours > 1)
			{
				timeStr += hours+' hours';
			}
			else if (hours === 1)
			{
				timeStr += hours+' hour';
			}

			if (minutes > 1)
			{
				if (timeStr.length > 0)
				{
					timeStr += ' ';
				}
				timeStr += minutes+' minutes';
			}
			else if (minutes === 1)
			{
				if (timeStr.length > 0)
				{
					timeStr += ' ';
				}
				timeStr += minutes+' minute';
			}

			setSigninError("Too many failed sign-in attempts, your account will be locked for "+timeStr+".");
		}
		else if (signinResponse.status >= 400 && signinResponse.status < 500)
		{
			setSigninError("Username or Password is incorrect");
		}
		else
		{
			if (signinResponse.message)
			{
				setSigninError("User sign-in error: "+signinResponse.message);
			}
			else
			{
				setSigninError("User sign-in error: Unknown");
			}
		}
	
		if (!signInSuccessful)
		{
			setShowSpinner(false);
		}
	}

	useEffect(() => {

		if (signInEffect)
		{
			setSignInEffect(false);
			setSigninError(undefined);
			doSignInRemote();
		}
		
		return () => {
		}

	}, [signInEffect]);

	const doSignIn = function()
	{
		setShowSpinner(true);
		setSignInEffect(true);
	}

	useHotkeys('enter', () => doSignIn(), {enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT']});

	let signinButtonActive = userUsername.trim().length > 0 && userPassword.trim().length > 0 && (!showChangePassword || userPasswordNewValid);

	return (
		<div>
			<Row>
				<Col xs="2"></Col>
				<Col className="signin-col" xs="8">
					{
						signinError && 
						<Alert variant="danger">{ signinError }</Alert>
					}
					<Card className="signin-card">
						<Card.Header>{ showChangePassword?"Change Password & Sign In":"Sign In" }</Card.Header>
						<Card.Body>
						{
								!showChangePassword &&
								<>
									<Form.Group controlId="userUsername">
										<Form.Label className="credentials-label">User Name</Form.Label>
										<Form.Control 
												autoFocus
												type="text" 
												name="userUsername"
												onChange={ (evt) => { setUserUsername(evt.target.value) } }
												value={ userUsername }
												/>
									</Form.Group>
									<Form.Group controlId="userPassword">
										<Form.Label className="credentials-label">{ showChangePassword?"Current Password":"Password" }</Form.Label>
										<InputGroup>
											<Form.Control 
												type={ showPassword?"text":"password" }
												name="userPassword"
												onChange={ (evt) => { setUserPassword(evt.target.value) } }
												value={ userPassword }
												/>
											<InputGroup.Append>
												<Button variant="primary-outline" tabIndex="-1" onClick={
													() => {
														setShowPassword(!showPassword);
													}
												}><FontAwesomeIcon icon={ showPassword?faEyeSlash:faEye } /></Button>
											</InputGroup.Append>
										</InputGroup>
									</Form.Group>
								</>
							}
							{
								showChangePassword &&
								<ChangePassword badWords={ [userUsername] } onChange={ (password, valid) => {
									setUserPasswordNew(password);
									setUserPasswordNewValid(valid);
								} }/>
							}
							<div className="signin-button text-right">
								<Button disabled={ !signinButtonActive } onClick={  async (evt) =>  { 
									doSignIn();
									}}>
										<span>{ showChangePassword?"Change Password & Sign In":"Sign In" }</span>
										<span>
											{ showSpinner && 
												<span>&nbsp;&nbsp;<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;&nbsp;</span> 							
											}
										</span>
									</Button>
							</div>
							<span><small><i>{ window.buildVersion?"Version: "+window.buildVersion.version:"" }</i></small></span>
						</Card.Body>
					</Card>
				</Col>
				<Col xs="2"></Col>
			</Row>
		</div>
	);
}

export default SignIn;
