import { signOut } from './userSlice';
import { clearLogData } from './logDataSlice';
import { clearProcessedSignups } from './processedSignupsSlice';
import { clearRawSignups } from './rawSignupsSlice';
import { clearUsers } from './usersWebSlice';

export function doSignOut(dispatch)
{
    dispatch(clearLogData({}));
    dispatch(clearProcessedSignups({}));
    dispatch(clearRawSignups({}));
    dispatch(clearUsers({}));
    dispatch(signOut({}));
}

export function doClearStore(dispatch)
{
    dispatch(clearLogData({}));
    dispatch(clearProcessedSignups({}));
    dispatch(clearRawSignups({}));
    dispatch(clearUsers({}));
}

