import { createSlice } from '@reduxjs/toolkit';
import { NavDropdown } from 'react-bootstrap';

const dateFormat = require("dateformat");

const INIT_DATA = {
	adminActivity: {
		loaded: false,
		calculated: false,
		sortKey: 'CompanyNum',
		direction: true,
		table: []
	},
	transLogDataEntry: {
		loaded: false,
		startDate: new Date().getTime(),
		table: []
	},
	logWeb: {
		loaded: false,
		startDate: new Date().getTime(),
		table: []
	}
};

export const logDataSlice = createSlice({
	name: 'logData',
	initialState: INIT_DATA,
	reducers: {
		clearLogData: (state, action) => {
			for(let key in INIT_DATA)
			{
				state[key] = INIT_DATA[key];
			}
		},
		setLogDataAdminActivity: (state, action) => {

			let newTable = [];
			let fieldMap = action.payload.table.fieldMap;

			for(let d=0;d<action.payload.table.data.length;d++)
			{
				let data = action.payload.table.data[d];
				newTable.push({ 
					ID: data[fieldMap["ID"]], 
					CompanyNum: data[fieldMap["CompanyNum"]], 
					LastDate: Date.parse(data[fieldMap["LastDate"]]),
					LastDateFormatted: dateFormat(data[fieldMap["LastDate"]], 'mm/dd/yyyy hh:MM TT'),
					TotalSignups: data[fieldMap["TotalSignups"]]
				 });
			}

			newTable.sort((a, b) => (a[state.adminActivity.sortKey] > b[state.adminActivity.sortKey]) ? (state.adminActivity.direction?1:-1) : (state.adminActivity.direction?-1:1));

			state.adminActivity.table = newTable;
			state.adminActivity.loaded = true;			
		},
		setLogDataAdminActivitySortKey: (state, action) => {
			state.adminActivity.sortKey = action.payload.sortKey;			
			state.adminActivity.direction = action.payload.direction;			
			state.adminActivity.table.sort((a, b) => (a[state.adminActivity.sortKey] > b[state.adminActivity.sortKey]) ? (state.adminActivity.direction?1:-1) : (state.adminActivity.direction?-1:1));
		},
		setLogDataAdminActivityCalculated: (state, action) => {
			state.adminActivity.calculated = true;			
		},
		setLogDataLogWeb: (state, action) => {

			let newTable = [];
			let fieldMap = action.payload.table.fieldMap;

			for(let d=0;d<action.payload.table.data.length;d++)
			{
				let data = action.payload.table.data[d];
				newTable.push({ 
					ID: data[fieldMap["ID"]], 
					UserName: data[fieldMap["UserName"]], 
					CompanyNum: data[fieldMap["CompanyNum"]], 
					LogItem: data[fieldMap["LogItem"]], 
					LogValue: data[fieldMap["LogValue"]], 
					LogStart: data[fieldMap["LogStart"]], 
					LogStop: data[fieldMap["LogStop"]], 
					LogDate: Date.parse(data[fieldMap["LogDate"]]),
					LogDateFormatted: dateFormat(data[fieldMap["LogDate"]], 'mm/dd/yyyy hh:MM TT'),
				 });
			}

			state.logWeb.table = newTable;
			state.logWeb.startDate = action.payload.startDate;
			state.logWeb.loaded = true;			
		},
		setLogDataLogTransDataEntry: (state, action) => {
			let newTable = [];
			let fieldMap = action.payload.table.fieldMap;

			for(let d=0;d<action.payload.table.data.length;d++)
			{
				let data = action.payload.table.data[d];
				newTable.push({ 
					ID: data[fieldMap["LogNum"]], 
					DataentryID: data[fieldMap["DataentryID"]], 
					FormSeqStart: data[fieldMap["FormSeqStart"]], 
					FormSeqEnd: data[fieldMap["FormSeqEnd"]], 
					Total: data[fieldMap["Total"]], 
					LogDate: Date.parse(data[fieldMap["LogDate"]]),
					LogDateFormatted: dateFormat(data[fieldMap["LogDate"]], 'mm/dd/yyyy hh:MM TT'),
				 });
			}

			state.transLogDataEntry.table = newTable;
			state.transLogDataEntry.startDate = action.payload.startDate;
			state.transLogDataEntry.loaded = true;			
		},
	},
});

export const getLogDataAdminActivity = (state) => {
	return state.logData.adminActivity;
}
export const getLogDataLogWeb = (state) => {
	return state.logData.logWeb;
}
export const getLogDataLogTransDataEntry = (state) => {
	return state.logData.transLogDataEntry;
}

export const { 
	clearLogData,
	setLogDataAdminActivity,
	setLogDataAdminActivitySortKey,
	setLogDataAdminActivityCalculated,
	setLogDataLogWeb,
	setLogDataLogTransDataEntry,
} = logDataSlice.actions;

export default logDataSlice.reducer;
