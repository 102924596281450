import { createSlice } from '@reduxjs/toolkit';

export const processedSignupsSlice = createSlice({
	name: 'processedSignups',
	initialState: {
		list: []
	},
	reducers: {
		addProcessedSignup: (state, action) => {
			let i=0;
			for(;i<state.list.length;i++)
			{
				if (action.payload.headerId == state.list[i].headerId)
				{
					state.list.splice(i, 1, action.payload.signupInfo);
					break;
				}
			}
			if (i === state.list.length)
			{
				state.list.splice(0, 0, action.payload.signupInfo);
			}
		},
		removeProcessedSignup: (state, action) => {
			let i=0;
			for(;i<state.list.length;i++)
			{
				if (action.payload.headerId == state.list[i].headerId)
				{
					state.list.splice(i, 1);
					break;
				}
			}
 		},
		clearProcessedSignups: (state, action) => {
			state.list = [];
 		},
	},
});

export const processedSignupsGetAllSignups = (state) => {
	return state.processedSignups.list;
}

export const { 
	addProcessedSignup,
	removeProcessedSignup,
	clearProcessedSignups,
} = processedSignupsSlice.actions;

export default processedSignupsSlice.reducer;
