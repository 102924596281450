export async function callServer(url, sendObj)
{
	let responseObj = {
		status: 500
	};

	try 
	{
		var timeout = 180000;

		const controller = new AbortController();
		const id = setTimeout(() => controller.abort(), timeout);

		let requestObj = {
			method: 'POST',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json'
			},
			redirect: 'follow',
			referrerPolicy: 'no-referrer',
			signal: controller.signal,
			body: JSON.stringify(sendObj),
		};
	  
		const response = await fetch(url, requestObj);

		clearTimeout(id);

		responseObj.response = await response.json();
		if (responseObj.response.status)
		{
			responseObj.status = responseObj.response.status;
		}
	}
	catch (e) 
	{
		responseObj.message = e.toString();
	}  

	return responseObj;
}
