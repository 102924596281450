import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col, Form, Table, Navbar, Nav, NavDropdown, NavItem, Spinner, Modal, Tabs, Tab } from 'react-bootstrap';
import { Link, Route, useParams, useRouteMatch, Redirect, Switch, NavLink } from "react-router-dom";

import SignupsProcessed from './SignupsProcessed'
import SignupsRawWeb from './SignupsRawWeb'

function SignupView(props)
{
	const { url, path } = useRouteMatch();

	return (
		<div>
			<Nav variant="tabs" defaultActiveKey="/home">
				<NavItem>
					<NavLink to={`${url}/processed`} className="nav-link">Processed Signups</NavLink>
				</NavItem>
				<NavItem>
					<NavLink to={`${url}/raw`} className="nav-link">Raw Web Signups</NavLink>
				</NavItem>
			</Nav>
			<Switch>
				<Route exact path={`${url}/processed`} component={ SignupsProcessed } />
				<Route exact path={`${url}/raw`} component={ SignupsRawWeb } />
				<Redirect from={`${url}`} to={`${url}/processed`} exact />
			</Switch>
		</div>
	);
}

export default SignupView;
