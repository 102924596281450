import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col, Form, Table, Navbar, Nav, NavDropdown, NavItem, Spinner, Modal, Tabs, Tab, InputGroup, Card, Alert } from 'react-bootstrap';

import { callServer } from '../utils/APIUtils';
import { doSignOut } from '../store/storeUtils';
import { selectUserCredentials } from '../store/userSlice';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faTrash } from '@fortawesome/fontawesome-free-solid'

import {
	addProcessedSignup,
	removeProcessedSignup,
	processedSignupsGetAllSignups,
} from '../store/processedSignupsSlice';

const dateFormat = require("dateformat");

function SignupsProcessed(props)
{
	const [idType, setIdType] = useState('detailid');
	const [idValue, setIdValue] = useState(''); // header: 4697956, detail: 3940811, fromseq: 16022646
	const [formLookupEffect, setFormLookupEffect] = useState(false);
	const [formLookupSpinner, setFormLookupSpinner] = useState(false);
	const [alertInfo, setAlertInfo] = useState(undefined);

	const dispatch = useDispatch();

	let credentials = useSelector(selectUserCredentials);
	let allProcessedSignups = useSelector(processedSignupsGetAllSignups);

	const getSignups = async function(valueObj)
	{
		let command = { 
			command: 'signup-get',
			idType: valueObj.idType,
			idValue: valueObj.idValue,
		};

		let responseObj = await callServer(command, credentials);
		//console.log(JSON.stringify(responseObj));
		if (responseObj)
		{
			if (responseObj.status === 200)
			{
				dispatch(addProcessedSignup( { lookupInfo: {idType: idType, idValue: idValue}, headerId: responseObj.headerId, signupInfo: responseObj } ));
			}
			else if (responseObj.status === 401)
			{
				doSignOut(dispatch);
			}
			else if (responseObj.status >= 400 && responseObj.status < 500)
			{
				setAlertInfo({ heading:"Signup Not Found", body: <p>The signup was not found.</p> });
			}
			else
			{
				setAlertInfo({ heading:"Error Opening Signup", body: <p>Error opening signup: { responseObj.message }</p> });
			}
		}
	}

	const lookupSignup = async function()
	{
		getSignups({ idType: idType, idValue: idValue });
		setFormLookupSpinner(false);
	}

	useEffect(() => {

		if (formLookupEffect)
		{
			setFormLookupEffect(false);
			lookupSignup();
		}
		
		return () => {
		}

	}, [formLookupEffect]);

	const doLookup = function()
	{
		setAlertInfo(undefined);
		setFormLookupSpinner(true);
		setFormLookupEffect(true);
	}

	return (
		<div className="admin-tab-body admin-rawsignups-body">
			{ alertInfo &&
				<Alert variant="danger" onClose={() => setAlertInfo(undefined) } dismissible><Alert.Heading>{ alertInfo.heading }</Alert.Heading>{ alertInfo.body }</Alert>
			}
			<InputGroup>
				<InputGroup.Prepend>
					<Form.Control 
						as="select"
						value={ idType }
						onChange={e => setIdType(e.target.value) }
						>
							<option key={ "detailid" } value={ "detailid" }>Detail ID</option>
							<option key={ "headerid" } value={ "headerid" }>Header ID</option>
							<option key={ "formseqnumber" } value={ "formseqnumber" }>Form Sequence Number</option>
					</Form.Control>
				</InputGroup.Prepend>
				<Form.Control 
					value={ idValue }
					onChange={e => setIdValue(e.target.value) }
					type="text" 
					/>
				<InputGroup.Append>
					<Button onClick={
						() => {
							doLookup();
						}
					}>
						<span>Get Signup</span>
						<span>
						{ formLookupSpinner && 
							<span>&nbsp;&nbsp;<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;&nbsp;</span> 							
						}
						</span>
					</Button>
				</InputGroup.Append>
			</InputGroup>
			<div className="admin-rawsignups-spacer"></div>
			{
				allProcessedSignups.map(function (item, index) {
					//console.log(JSON.stringify(item, null, 2));
					let row = item.rows.length>0?item.rows[0]:undefined;
					return <div key={ item.headerId }>
							<Card body>
								<Card.Body >
									<Card.Title>
									<Row>
											<Col>
											
											</Col>
											<Col style={{ textAlign: "right" }}>
												<Button variant="outline-primary" onClick={
													() => {
														dispatch(removeProcessedSignup( { headerId: item.headerId } ));
													}
												}><FontAwesomeIcon size="sm" icon={ faTimes } /></Button>
											</Col>
										</Row>
									</Card.Title>
									{
										/*
{
  "DETAIL_DETAILID": 4697956,
  "DETAIL_HEADERID": 3940811,
  "DETAIL_BILLER": "100",
  "DETAIL_BILLERACCT": "e2G3VJzPJDv5tsyPmyTeig==",
  "DETAIL_NOTES": null,
  "DETAIL_TYPE": "C",
  "DETAIL_ACTIVE": 1,
  "DETAIL_DETAILDATE": "2018-01-01T07:11:00.000Z",
  "HEADER_HEADERID": 3940811,
  "HEADER_OPERATORID": "JEG",
  "HEADER_FORMSEQNUM": 16022646,
  "HEADER_TYPE": "C",
  "HEADER_FORMSSOURCE": 6,
  "HEADER_CUSTFIRSTMI": "b9CbqYLbXcE=",
  "HEADER_CUSTLAST": "/Bce9+JJ95M=",
  "HEADER_ADDRESS": "qDN83LYs5k+uIO5FcaqugQ==",
  "HEADER_CITY": "TOLEDO",
  "HEADER_STATE": "OH",
  "HEADER_ZIP": "43611",
  "HEADER_PHONENUMBER": "BVqSNmkBockJE4Dw5KAojQ==",
  "HEADER_CUSTDATE": "12/29/2017",
  "HEADER_FINAME": "HUNTINGTON NATIONAL BANK",
  "HEADER_FIRT": "0412-1501-6",
  "HEADER_TYPEACCT": "C",
  "HEADER_ACCT": "dtL7GQzWz0Yt52exvujH9g==",
  "HEADER_AUTHCOPY": "N",
  "HEADER_NOTE": null,
  "HEADER_NAMEINDICATOR": null,
  "HEADER_DATE": "2018-01-01T07:11:00.000Z",
  "HEADER_EMAIL": "d/V45L4hWFSotkdqh98YfSG3n+prjrUQ",
  "HEADER_MAILCONFIRM": 0,
  "HEADER_CHECKENCLOSED": 1,
  "HEADER_FORMSIGNED": 1,
  "HEADER_USERPIN": "Drcr023XFko=",
  "PKEY": "A1#ZGJjOGFmMTBmMTdlMDljZjlhYzBhYzFkZDc2MThmZjYyYjE1M2Q5NjFlYzAyYWYwMmY0ZDc0YzljYmUyYjUxZA==#KJeZpyNO2KwTQcPP4XbDMA==",
  "HEADER_FIRTO": "0412-1501-6"
}										*/
									}

									{ row &&
										<div>
											<Table striped bordered size="sm" className="processed-signups-table">
												<thead>
													<tr><th colSpan="2">Signup Info</th></tr>
												</thead>
												<tbody>
													<tr><td>Header ID</td><td>{ row["DETAIL_HEADERID"] }</td></tr>
													<tr><td>Form Seq Number</td><td>{ row["HEADER_FORMSEQNUM"] }</td></tr>
													<tr><td>Process Date</td><td>{ dateFormat(row["HEADER_DATE"], 'm/d/yy h:MM TT') }</td></tr>
													<tr><td>Customer Date</td><td>{ row["HEADER_CUSTDATE"] || '' }</td></tr>
													<tr><td>Operator</td><td>{ row["HEADER_OPERATORID"] }</td></tr>
													<tr><td>Header Status</td><td>{ row["HEADER_TYPE"]==='C'?"C - Complete":"N - Incomplete" }</td></tr>
													<tr><td>Form Source</td><td>{ row["HEADER_FORMSSOURCENAME"] }</td></tr>
													<tr><td>Auth Copy</td><td>{ row["HEADER_AUTHCOPY"] }</td></tr>
													<tr><td>Mail Confirm</td><td>{ row["HEADER_MAILCONFIRM"]?"Yes":"No" }</td></tr>
													<tr><td>Check Enclosed</td><td>{ row["HEADER_CHECKENCLOSED"]?"Yes":"No" }</td></tr>
													<tr><td>Form Signed</td><td>{ row["HEADER_FORMSIGNED"]?"Yes":"No" }</td></tr>
													<tr><td>Header Note</td><td>{ row["HEADER_NOTE"] }</td></tr>
												</tbody>
											</Table>
											<Table striped bordered size="sm" className="processed-signups-table">
												<thead>
													<tr><th colSpan="2">Banking Info</th></tr>
												</thead>
												<tbody>
													<tr><td>Bank R/T</td><td>{ row["HEADER_FIRT"] }</td></tr>
													<tr><td>Bank Name</td><td>{ row["HEADER_FINAME"] }</td></tr>
													<tr><td>Bank Acct</td><td>{ row["HEADER_ACCT"] }</td></tr>
													<tr><td>Acct Type</td><td>{ row["HEADER_TYPEACCT"] === 'C'?"C - Checking":"S - Savings" }</td></tr>
												</tbody>
											</Table>
											<Table striped bordered size="sm" className="processed-signups-table">
												<thead>
													<tr><th colSpan="2">Personal Info</th></tr>
												</thead>
												<tbody>
													<tr><td>First Name</td><td>{ row["HEADER_CUSTFIRSTMI"] }</td></tr>
													<tr><td>Last Name</td><td>{ row["HEADER_CUSTLAST"] }</td></tr>
													<tr><td>Address</td><td>{ row["HEADER_ADDRESS"] }</td></tr>
													<tr><td>City</td><td>{ row["HEADER_CITY"] }</td></tr>
													<tr><td>State</td><td>{ row["HEADER_STATE"] }</td></tr>
													<tr><td>Zip</td><td>{ row["HEADER_ZIP"] }</td></tr>
													<tr><td>Phone</td><td>{ row["HEADER_PHONENUMBER"] || '' }</td></tr>
													<tr><td>EMail</td><td>{ row["HEADER_EMAIL"] || '' }</td></tr>
												</tbody>
											</Table>
										</div>
									}
									<hr/>
									{
										item.rows.map((row, index) => {

											return <div>
												<Table striped bordered size="sm" className="processed-signups-table">
													<thead>
														<tr><th colSpan="2">Detail { (index + 1) }</th></tr>
													</thead>
													<tbody>
														<tr><td>Detail ID</td><td>{ row["DETAIL_DETAILID"] }</td></tr>
														<tr><td>Biller ID</td><td>{ row["DETAIL_BILLER"] }</td></tr>
														<tr><td>Biller Name</td><td>{ row["DETAIL_BILLERNAME"] }</td></tr>
														<tr><td>Biller Acct</td><td>{ row["DETAIL_BILLERACCT"] }</td></tr>
														<tr><td>Detail Note</td><td>{ row["DETAIL_NOTES"] }</td></tr>
														<tr><td>Detail Status</td><td>{ row["DETAIL_TYPE"]==='C'?"C - Complete":"N - Incomplete" }</td></tr>
														<tr><td>Downloaded</td><td>{ row.hasOwnProperty("ISSIGNUPDOWNLOADED") && row["ISSIGNUPDOWNLOADED"]?"Yes":"No" }</td></tr>
														<tr><td>Active</td><td>{ row["DETAIL_ACTIVE"]?"Yes":"No" }&nbsp;&nbsp;&nbsp;<Button variant="outline-primary" size="sm" onClick={
															async () =>{

																let commandSetActive = { 
																	command: 'signup-active-set',
																	details: [{ id: row["DETAIL_DETAILID"], active: (row["DETAIL_ACTIVE"]?false:true) }]
																};
												
																let responseObj = await callServer(commandSetActive, credentials);
																if (responseObj && responseObj.status === 200)
																{
																	getSignups({ idType: "headerid", idValue: row["DETAIL_HEADERID"] });
																}
																else if (responseObj && responseObj.status === 401)
																{
																	doSignOut(dispatch);
																}
															}
														}>{ row["DETAIL_ACTIVE"]?"Make InActive":"Make Active" }</Button></td></tr>
													</tbody>
												</Table>
											</div>
										})
									}
								</Card.Body>
							</Card>
							<div className="admin-rawsignups-card-spacer"></div>
						</div>
				})
			}
		</div>
	);
}

export default SignupsProcessed;
