import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col, Form, Table, Navbar, Nav, NavDropdown, NavItem, Spinner, Modal, Tabs, Tab, InputGroup, Card, Alert } from 'react-bootstrap';

import { callServer } from '../utils/APIUtils';
import { doSignOut } from '../store/storeUtils';
import { selectUserCredentials } from '../store/userSlice';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faTrash } from '@fortawesome/fontawesome-free-solid'

import {
	addRawSignup,
	removeRawSignup,
	rawSignupsGetAllSignups,
} from '../store/rawSignupsSlice';

function SignupsRawWeb(props)
{
	const [formSeqNumber, setFormSeqNumber] = useState('');
	const [formSeqNumberLookupEffect, setFormSeqNumberLookupEffect] = useState(false);
	const [formSeqNumberLookupSpinner, setFormSeqNumberLookupSpinner] = useState(false);
	const [alertInfo, setAlertInfo] = useState(undefined);
	const [deleteSignupEffect, setDeleteSignupEffect] = useState(undefined);
	const [deleteSignupSpinner, setDeleteSignupSpinner] = useState(false);
	const [unDeleteSignupSpinner, setUnDeleteSignupSpinner] = useState(false);

	const dispatch = useDispatch();

	let credentials = useSelector(selectUserCredentials);
	let allRawSignups = useSelector(rawSignupsGetAllSignups);

	const getSignup = async function(formSeqNumber)
	{
		let command = { 
			command: 'rawsignup-get',
			formSeqNumber: formSeqNumber
		};

		let responseObj = await callServer(command, credentials);
		if (responseObj)
		{
			if (responseObj.status === 200)
			{
				dispatch(addRawSignup( { formSeqNumber: formSeqNumber, signupInfo: responseObj } ));
			}
			else if (responseObj.status === 401)
			{
				doSignOut(dispatch);
			}
			else if (responseObj.status >= 400 && responseObj.status < 500)
			{
				setAlertInfo({ heading:"Signup Not Found", body: <p>The signup with form sequence number { formSeqNumber } not found.</p> });
			}
			else
			{
				setAlertInfo({ heading:"Error Opening Signup", body: <p>Error opening signup: { responseObj.message }</p> });
			}
		}
	}

	const lookupSignup = async function(formSeqNumber)
	{
		getSignup(formSeqNumber);
		setFormSeqNumberLookupSpinner(false);
	}

	useEffect(() => {

		if (formSeqNumberLookupEffect)
		{
			setFormSeqNumberLookupEffect(false);
			lookupSignup(formSeqNumber);
		}
		
		return () => {
		}

	}, [formSeqNumberLookupEffect]);


	const moveSignup = async function(formSeqNumber, destination)
	{
		let command = { 
			command: 'rawsignup-move',
			formSeqNumber: formSeqNumber,
			destination: destination
		};

		let responseObj = await callServer(command, credentials);
		if (responseObj)
		{
			if (responseObj.status === 200)
			{
				getSignup(formSeqNumber);
			}
			else if (responseObj.status === 401)
			{
				doSignOut(dispatch);
			}
			else if (responseObj.status >= 400 && responseObj.status < 500)
			{
				setAlertInfo({ heading:"Signup Not Found", body: <p>The signup with form sequence number { formSeqNumber } not found.</p> });
			}
			else
			{
				setAlertInfo({ heading:"Error Moving Signup", body: <p>Error moving signup: { responseObj.message }</p> });
			}
		}

		setDeleteSignupSpinner(false);
		setUnDeleteSignupSpinner(false);
	}

	useEffect(() => {

		if (deleteSignupEffect)
		{
			let formSeqNumber = deleteSignupEffect.formInfo.signupObj.formData.formSeqNumber;
			let destination = deleteSignupEffect.destination;

			setDeleteSignupEffect(undefined);
			moveSignup(formSeqNumber, destination);
		}
		
		return () => {
		}

	}, [deleteSignupEffect]);

	const doLookup = function()
	{
		setAlertInfo(undefined);
		setFormSeqNumberLookupSpinner(true);
		setFormSeqNumberLookupEffect(true);
	}

	const doDeleteSignup = function(formInfo)
	{
		setAlertInfo(undefined);
		setDeleteSignupSpinner(true);
		setDeleteSignupEffect({ formInfo: formInfo, destination: 'deleted' });
	}

	const doUnDeleteSignup = function(formInfo)
	{
		setAlertInfo(undefined);
		setUnDeleteSignupSpinner(true);
		setDeleteSignupEffect({ formInfo: formInfo, destination: 'archived' });
	}

	return (
		<div className="admin-tab-body admin-rawsignups-body">
			{ alertInfo &&
				<Alert variant="danger" onClose={() => setAlertInfo(undefined) } dismissible><Alert.Heading>{ alertInfo.heading }</Alert.Heading>{ alertInfo.body }</Alert>
			}
			<InputGroup>
				<InputGroup.Prepend>
					<InputGroup.Text>Form Sequence Number</InputGroup.Text>
				</InputGroup.Prepend>
				<Form.Control 
					value={ formSeqNumber }
					onChange={e => setFormSeqNumber(e.target.value) }
					type="text" 
					/>
				<InputGroup.Append>
					<Button onClick={
						() => {
							doLookup();
						}
					}>
						<span>Get Signup</span>
						<span>
						{ formSeqNumberLookupSpinner && 
							<span>&nbsp;&nbsp;<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;&nbsp;</span> 							
						}
						</span>
					</Button>
				</InputGroup.Append>
			</InputGroup>
			<div className="admin-rawsignups-spacer"></div>
			{
				allRawSignups.length <= 0 ?
				<span><i><strong>Note: </strong>All Internet signups that start with <strong>179</strong> are available.</i></span>
				:
				allRawSignups.map(function (item, index) {
					//console.log(JSON.stringify(item, null, 2));
					return <div key={ item.signupObj.formData.formSeqNumber }>
							<Card body>
								<Card.Body >
									<Card.Title>
										<Row>
											<Col>
												{ item.isDeleted?
													<><FontAwesomeIcon size="sm" icon={ faTrash } />&nbsp;Deleted Raw Signup {item.signupObj.formData.formSeqNumber}</>
													:
													item.isMovable ? 
													<>Active Raw Signup {item.signupObj.formData.formSeqNumber}</> 
													:
													<>Processed Raw Signup {item.signupObj.formData.formSeqNumber}</> 
												}
											</Col>
											<Col style={{ textAlign: "right" }}>
												<Button variant="outline-primary" onClick={
													() => {
														dispatch(removeRawSignup( { formSeqNumber: item.signupObj.formData.formSeqNumber } ));
													}
												}><FontAwesomeIcon size="sm" icon={ faTimes } /></Button>
											</Col>
										</Row>
									</Card.Title>
									<p>{ item.s3Path }</p>
									<pre>{ JSON.stringify(item.signupObj.formData, null, 2) }</pre>
									{
										item.isDeleted ? 
										<p><Button onClick={ () => { doUnDeleteSignup(item) } }>
											<span>Restore Signup to Active Signups</span>
											<span>
											{ unDeleteSignupSpinner && 
												<span>&nbsp;&nbsp;<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;&nbsp;</span> 							
											}
											</span>
										</Button></p>
										:
										item.isMovable ? 
										<p><Button onClick={ () => { doDeleteSignup(item) } }>
											<span>Move Signup to Deleted Signups</span>
											<span>
											{ deleteSignupSpinner && 
												<span>&nbsp;&nbsp;<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;&nbsp;</span> 							
											}
											</span>
										</Button></p>
										:
										<p><i><strong>Note: </strong>It is too late to delete this signup</i></p>
									}
								</Card.Body>
							</Card>
							<div className="admin-rawsignups-card-spacer"></div>
						</div>
				})
			}
		</div>
	);
}

export default SignupsRawWeb;
