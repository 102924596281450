import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col, Form, Table, Navbar, Nav, NavDropdown, NavItem, Spinner, Modal, Tabs, Tab } from 'react-bootstrap';
import { Link, Route, useParams, useRouteMatch, Redirect, Switch, NavLink } from "react-router-dom";

import LogsDataEntry from './LogsDataEntry'
import LogsNewByBiller from './LogsNewByBiller'
import LogsUser from './LogsUser'

/*
Logs 
[User log]
[Data Entry log]
[2am run log]
[New Signups by Biller]
*/

function LogView(props)
{
	const { url, path } = useRouteMatch();

	return (
		<div>
			<Nav variant="tabs" defaultActiveKey="/home">
				<NavItem>
					<NavLink to={`${url}/user`} className="nav-link">User Log</NavLink>
				</NavItem>
				<NavItem>
					<NavLink to={`${url}/dataentry`} className="nav-link">Data Entry Log</NavLink>
				</NavItem>
				<NavItem>
					<NavLink to={`${url}/newbybiller`} className="nav-link">New Signups by Biller</NavLink>
				</NavItem>
			</Nav>
			<Switch>
				<Route exact path={`${url}/user`} component={ LogsUser } />
				<Route exact path={`${url}/dataentry`} component={ LogsDataEntry } />
				<Route exact path={`${url}/newbybiller`} component={ LogsNewByBiller } />
				<Redirect from={`${url}`} to={`${url}/user`} exact />
			</Switch>
		</div>
	);
}

export default LogView;
