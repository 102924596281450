import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col, Form, Table, Navbar, Nav, NavDropdown, NavItem, Spinner, Modal, Tabs, Tab, InputGroup, Card, Alert, Toast, Badge, ProgressBar } from 'react-bootstrap';

import DatePicker from "react-datepicker";

import { callServer } from '../utils/APIUtils';
import { doSignOut } from '../store/storeUtils';
import { selectUserCredentials } from '../store/userSlice';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown, faUser, faFile, faLock, faUnlock, faThumbsUp, faThumbsDown, faEdit } from '@fortawesome/fontawesome-free-solid'

import "react-datepicker/dist/react-datepicker.css";

import {
	setLogDataAdminActivity,
	setLogDataAdminActivitySortKey,
	setLogDataAdminActivityCalculated,
	setLogDataLogWeb,
	setLogDataLogTransDataEntry,
	getLogDataAdminActivity,
	getLogDataLogWeb,
	getLogDataLogTransDataEntry,
} from '../store/logDataSlice';

const LOGITEMS = {
	LOGLOGIN: 1,
	LOGLOGOUT: 2,
	LOGSIGNUPS: 3,
	LOGEXPORT: 4,
	LOGREADMAIL: 5,
	LOGDELMAIL: 6,
	LOGSESSION: 7,
	LOGCHANGEPASSWORD: 8,
	LOGEDITRECORD: 9,
	LOGSECUREFILE: 10,
	LOGSTATS: 11,
}

const LOGLOGINSTATUSES = {
	OK: "OK",
	UNLOCK: "UnLock",
	ERRORUSERNAME: "Error|UserName",
	ERRORPASSWORD: "Error|Password",
	ERRORLOCKOUT: "Error|Lockout",
	ERRORDATAENTRY: "Error|DataEntry",
	ERRORNOTACTIVE: "Error|NotActive",
	ERRORWRONGACCESS: "Error|WrongAccess",
}

const DAYSTOSHOW = 15;

function getLogDetails(item)
{
	let details = {
		logItem: <span>&nbsp;</span>,
		logValue: <span>&nbsp;</span>,
	}

	switch(item.LogItem)
	{
		case LOGITEMS.LOGLOGIN:
			details.logItem = <span><FontAwesomeIcon icon={ faUser } />&nbsp;Sign In</span>;
			switch(item.LogValue)
			{
				case LOGLOGINSTATUSES.OK:
					details.logValue = <span><FontAwesomeIcon icon={ faThumbsUp } />&nbsp;Successful</span>;
					break;
				case LOGLOGINSTATUSES.UNLOCK:
					details.logValue = <span><FontAwesomeIcon icon={ faUnlock } />&nbsp;Unlock</span>;
					break;
				case LOGLOGINSTATUSES.ERRORUSERNAME:
					details.logValue = <span><FontAwesomeIcon icon={ faThumbsDown } />&nbsp;Bad Username</span>;
					break;
				case LOGLOGINSTATUSES.ERRORPASSWORD:
					details.logValue = <span><FontAwesomeIcon icon={ faThumbsDown } />&nbsp;Incorrect Password</span>;
					break;
				case LOGLOGINSTATUSES.ERRORLOCKOUT:
					details.logValue = <span><FontAwesomeIcon icon={ faLock } />&nbsp;Lock Out</span>;
					break;
				case LOGLOGINSTATUSES.ERRORDATAENTRY:
					details.logValue = <span><FontAwesomeIcon icon={ faThumbsDown } />&nbsp;Data Entry</span>;
					break;
				case LOGLOGINSTATUSES.ERRORNOTACTIVE:
					details.logValue = <span><FontAwesomeIcon icon={ faThumbsDown } />&nbsp;User InActive</span>;
					break;
				case LOGLOGINSTATUSES.ERRORWRONGACCESS:
					details.logValue = <span><FontAwesomeIcon icon={ faThumbsDown } />&nbsp;Wrong Access</span>;
					break;
			}
			break;
		case LOGITEMS.LOGLOGOUT:
			details.logItem = <span><FontAwesomeIcon icon={ faUser } />&nbsp;Sign Out</span>;
			break;
		case LOGITEMS.LOGSIGNUPS:
			details.logItem = <span><FontAwesomeIcon icon={ faFile } />&nbsp;View Signups</span>;
			details.logValue = <span>Biller { item.CompanyNum }, Range { item.LogStart } - { item.LogStop }</span>;
			break;
		case LOGITEMS.LOGEXPORT:
			details.logItem = <span><FontAwesomeIcon icon={ faFile } />&nbsp;Export</span>;
			details.logValue = <span>Biller { item.CompanyNum }, Range { item.LogStart } - { item.LogStop }</span>;
			break;
		case LOGITEMS.LOGREADMAIL:
			details.logItem = <span><FontAwesomeIcon icon={ faUser } />&nbsp;Read EMail</span>;
			break;
		case LOGITEMS.LOGDELMAIL:
			details.logItem = <span><FontAwesomeIcon icon={ faUser } />&nbsp;Delete EMail</span>;
			break;
		case LOGITEMS.LOGSESSION:
			details.logItem = <span><FontAwesomeIcon icon={ faUser } />&nbsp;Session</span>;
			details.logValue = <span>{ item.LogValue }</span>;
			break;
		case LOGITEMS.LOGCHANGEPASSWORD:
			details.logItem = <span><FontAwesomeIcon icon={ faUser } />&nbsp;Change Password</span>;
			break;
		case LOGITEMS.LOGEDITRECORD:
			details.logItem = <span><FontAwesomeIcon icon={ faEdit } />&nbsp;Edit Signup</span>;
			details.logValue = <span>Biller { item.CompanyNum }&nbsp;/&nbsp;Ref# { item.LogValue }</span>;
			break;
		case LOGITEMS.LOGSECUREFILE:
			details.logItem = <span><FontAwesomeIcon icon={ faUser } />&nbsp;Secure File</span>;
			break;
		case LOGITEMS.LOGSTATS:
			details.logItem = <span><FontAwesomeIcon icon={ faUser } />&nbsp;Log Stats</span>;
			break;
		}

	return details;
}

function LogsUser(props)
{
	const [loadingLogs, setLoadingLogs] = useState(true);

	const dispatch = useDispatch();

	let credentials = useSelector(selectUserCredentials);
	let logDataLogWeb = useSelector(getLogDataLogWeb);

	const loadLogs = async function(startDate)
	{
		let now = new Date();

		if ((now.getTime() - startDate.getTime()) < (24 * 60 * 60 * 1000))
		{
			//console.log("loadTransactionLog: move to now");

			startDate = now;
		}

		let commandGet = { 
			command: 'table-get',
			arguments: { 
				get: 'logweb',
				days: DAYSTOSHOW,
				startDate: startDate
			}
		};

		let responseObj = await callServer(commandGet, credentials);
		//console.log(JSON.stringify(responseObj));
		if (responseObj && responseObj.status === 200 && responseObj.tables && responseObj.tables.length > 0)
		{
			//console.log(JSON.stringify(responseObj.tables[0]));
			dispatch(setLogDataLogWeb({ startDate: startDate.getTime(), table: responseObj.tables[0] }));
		}
		else if (responseObj && responseObj.status === 401)
		{
			doSignOut(dispatch);
		}

		setLoadingLogs(false);
	}

	const updateStartDate = async function(startDate)
	{
		setLoadingLogs(true);
		loadLogs(startDate);
	}

	useEffect(() => {

		if(!logDataLogWeb.loaded)
		{
			setLoadingLogs(true);
			loadLogs(new Date());
		}
		else
		{
			setLoadingLogs(false);
		}
		
		return () => {
		}

	}, []);

	return (
		<div className="admin-tab-body">
			<div className="admin-rawsignups-spacer"></div>
			<span>Start Date&nbsp;&nbsp;</span>
			<DatePicker
				todayButton="Today"
				selected={ new Date(logDataLogWeb.startDate) }
				maxDate={ new Date() }
				onChange={ date => updateStartDate(date) }
				/>
			<div className="admin-rawsignups-spacer"></div>
			{
				loadingLogs ?
				<span><Spinner size="sm" animation="border" />&nbsp;Loading&hellip;</span>
				:
				<>
					<Table striped bordered size="sm">
						<thead>
							<tr>
								<th style={{ width: "25%" }}>Date</th>
								<th style={{ width: "20%" }}><FontAwesomeIcon icon={ faUser } />&nbsp;User Name</th>
								<th style={{ width: "20%" }}>Action</th>
								<th style={{ width: "40%" }}>Status</th>
							</tr>
						</thead>
						<tbody>
							{
								logDataLogWeb.table.map((item) => {

									let details = getLogDetails(item);

									return <tr key={ item.ID }>
										<td style={{ fontFamily: "monospace, monospace" }}>{ item.LogDateFormatted }</td>
										<td>{ item.UserName }</td>
										<td>{ details.logItem }</td>
										<td>{ details.logValue }</td>
									</tr>
								})
							}
						</tbody>
					</Table>
					<i><strong>Note: </strong>{ DAYSTOSHOW } days of activity</i><br/><br/>
				</>
			}
		</div>
	);
}

export default LogsUser;
