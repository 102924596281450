import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './App.css';
import { Button, Row, Col, Form, Table, Navbar, Nav, NavDropdown, NavItem, Spinner, Modal, Tabs, Tab } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/fontawesome-free-solid'

import { callServer } from './utils/APIUtils';

import SignIn from './views/SignIn';
import SignupsView from './views/SignupsView';
import UserView from './views/UserView';
import LogView from './views/LogView';
import StopWebView from './views/StopWebView';

import ChangePasswordModal from './modals/ChangePasswordModal';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	NavLink,
	useParams,
	Redirect,
} from "react-router-dom";

import {
	isUserSignIn,
	selectUserInfo,
	signOut,
	selectUserCredentials
} from './store/userSlice';

import {
	isChangePasswordShow,
	setChangePasswordShow,
} from './store/changePasswordSlice';

import { doSignOut } from './store/storeUtils';

function App() 
{
	let signedIn = useSelector(isUserSignIn);
	let userUserInfo = useSelector(selectUserInfo);
	let credentials = useSelector(selectUserCredentials);

	const dispatch = useDispatch();

	let userTitle = userUserInfo?<span><FontAwesomeIcon icon={ faUser } />&nbsp;&nbsp;{ userUserInfo.username }&nbsp;</span>:<span></span>;

	return (
		<div className="main-body">
			<ChangePasswordModal />
			<Router basename={process.env.PUBLIC_URL}>
				<Navbar bg="light" expand="lg" className="navbar-container">
					<Navbar.Brand><Link to="/signups" className="brand-router"><img width="32" src={ process.env.PUBLIC_URL + '/StopSign-nofont.svg' }/>&nbsp;&nbsp;Administration</Link></Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<div className="navbar-brand-spacer"></div>
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav variant="pills" className="mr-auto">
							{
								signedIn && 
								<>
									<NavItem>
										<NavLink to="/signups" className="nav-link top-nav-tab">Signups</NavLink>
									</NavItem>
									<NavItem>
										<NavLink exact to="/users" className="nav-link top-nav-tab">Users</NavLink>
									</NavItem>
									<NavItem>
										<NavLink to="/logs" className="nav-link top-nav-tab">Logs &amp; Statistics</NavLink>
									</NavItem>
									<NavItem>
										<NavLink exact to="/stopdbsync" className="nav-link top-nav-tab">StopDB Sync</NavLink>
									</NavItem>
								</>
							}
						</Nav>						
						<Nav>
							{
								signedIn && 
								<NavDropdown title={ userTitle } className="navbar-dropdown-hello" id="basic-nav-dropdown" alignRight>
									<NavDropdown.Item onClick={
											(evt) => {
												dispatch(setChangePasswordShow({ show: true }));
											}
										}>Change Password</NavDropdown.Item>
									<NavDropdown.Item onClick={
											async (evt) => {
												await callServer({ command: 'user-signout' }, credentials);

												doSignOut(dispatch);
											}
										}>Sign Out</NavDropdown.Item>
								</NavDropdown>
							}
						</Nav>
					</Navbar.Collapse>
				</Navbar>
				<div className="navbar-spacer"></div>
				<div className="body-div">
					{
						signedIn ? 
						<Switch>
							<Redirect from="/" to="/signups" exact />
							<Redirect from="/index.html" to="/signups" exact />
							<Route path="/signups" component={ SignupsView } />
							<Route exact path="/users" component={ UserView } />
							<Route path="/logs" component={ LogView } />
							<Route exact path="/stopdbsync" component={ StopWebView } />
						</Switch>
						:
						<SignIn />
					}
				</div>
			</Router>
		</div>
	);
}

export default App;
