import { createSlice } from '@reduxjs/toolkit';

export const rawSignupsSlice = createSlice({
	name: 'rawSignups',
	initialState: {
		list: []
	},
	reducers: {
		addRawSignup: (state, action) => {
			let i=0;
			for(;i<state.list.length;i++)
			{
				let formSeqNumber = undefined;
				if (state.list[i].hasOwnProperty(formSeqNumber))
				{
					formSeqNumber = state.list[i].formSeqNumber;
				}
				else if (state.list[i].hasOwnProperty("formData") && state.list[i].formData.hasOwnProperty("formSeqNumber"))
				{
					formSeqNumber = state.list[i].formData.formSeqNumber;
				}

				if (action.payload.formSeqNumber == formSeqNumber)
				{
					state.list.splice(i, 1, action.payload.signupInfo);
					break;
				}
			}
			if (i === state.list.length)
			{
				state.list.splice(0, 0, action.payload.signupInfo);
			}
		},
		removeRawSignup: (state, action) => {
			let i=0;
			for(;i<state.list.length;i++)
			{
				let formSeqNumber = undefined;
				if (state.list[i].hasOwnProperty(formSeqNumber))
				{
					formSeqNumber = state.list[i].formSeqNumber;
				}
				else if (state.list[i].hasOwnProperty("formData") && state.list[i].formData.hasOwnProperty("formSeqNumber"))
				{
					formSeqNumber = state.list[i].formData.formSeqNumber;
				}

				if (action.payload.formSeqNumber === formSeqNumber)
				{
					state.list.splice(i, 1);
					break;
				}
			}
 		},
		clearRawSignups: (state, action) => {
			state.list = [];
 		},
	},
});

export const rawSignupsGetAllSignups = (state) => {
	return state.rawSignups.list;
}

export const { 
	addRawSignup,
	removeRawSignup,
	clearRawSignups,
} = rawSignupsSlice.actions;

export default rawSignupsSlice.reducer;
