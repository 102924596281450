import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col, Form, Table, Navbar, Nav, NavDropdown, NavItem, Spinner, Modal, Tabs, Tab, InputGroup, Card, Alert, Toast, Badge, ProgressBar } from 'react-bootstrap';

import DatePicker from "react-datepicker";

import { callServer } from '../utils/APIUtils';
import { doSignOut } from '../store/storeUtils';
import { selectUserCredentials } from '../store/userSlice';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/fontawesome-free-solid'

import "react-datepicker/dist/react-datepicker.css";

import {
	setLogDataAdminActivity,
	setLogDataAdminActivitySortKey,
	setLogDataAdminActivityCalculated,
	setLogDataLogWeb,
	setLogDataLogTransDataEntry,
	getLogDataAdminActivity,
	getLogDataLogWeb,
	getLogDataLogTransDataEntry,
} from '../store/logDataSlice';

const DAYSTOSHOW = 15;

function LogsDataEntry(props)
{
	const [loadingLogs, setLoadingLogs] = useState(true);

	const dispatch = useDispatch();

	let credentials = useSelector(selectUserCredentials);
	let logDataLogTransDataEntry = useSelector(getLogDataLogTransDataEntry);

	const loadLogs = async function(startDate)
	{
		let now = new Date();

		if ((now.getTime() - startDate.getTime()) < (24 * 60 * 60 * 1000))
		{
			//console.log("loadTransactionLog: move to now");

			startDate = now;
		}

		let commandGet = { 
			command: 'table-get',
			arguments: { 
				get: 'transactionlogdataentry',
				days: DAYSTOSHOW,
				startDate: startDate
			}
		};

		let responseObj = await callServer(commandGet, credentials);
		//console.log(JSON.stringify(responseObj));
		if (responseObj && responseObj.status === 200 && responseObj.tables && responseObj.tables.length > 0)
		{
			//console.log(JSON.stringify(responseObj.tables[0]));
			dispatch(setLogDataLogTransDataEntry({ startDate: startDate.getTime(), table: responseObj.tables[0] }));
		}
		else if (responseObj && responseObj.status === 401)
		{
			doSignOut(dispatch);
		}

		setLoadingLogs(false);
	}

	const updateStartDate = async function(startDate)
	{
		setLoadingLogs(true);
		loadLogs(startDate);
	}

	useEffect(() => {

		if(!logDataLogTransDataEntry.loaded)
		{
			setLoadingLogs(true);
			loadLogs(new Date());
		}
		else
		{
			setLoadingLogs(false);
		}
		
		return () => {
		}

	}, []);
	return (
		<div className="admin-tab-body">
			<div className="admin-rawsignups-spacer"></div>
			<span>Start Date&nbsp;&nbsp;</span>
			<DatePicker
				todayButton="Today"
				selected={ new Date(logDataLogTransDataEntry.startDate) }
				maxDate={ new Date() }
				onChange={ date => updateStartDate(date) }
				/>
			<div className="admin-rawsignups-spacer"></div>
			{
				loadingLogs ?
				<span><Spinner size="sm" animation="border" />&nbsp;Loading&hellip;</span>
				:
				<>
				<Table striped bordered size="sm">
					<thead>
						<tr>
							<th style={{ width: "15%" }}><FontAwesomeIcon icon={ faUser } />&nbsp;User</th>
							<th style={{ width: "25%" }}>Date</th>
							<th style={{ width: "15%" }}>Total Forms</th>
							<th style={{ width: "45%" }}>Form Seq Number Range</th>
						</tr>
					</thead>
					<tbody>
						{
							logDataLogTransDataEntry.table.map((item) => {

								return <tr key={ item.ID }>
									<td>{ item.DataentryID }</td>
									<td style={{ fontFamily: "monospace, monospace" }}>{ item.LogDateFormatted }</td>
									<td>{ item.Total }</td>
									<td>{ item.FormSeqStart + " - " + item.FormSeqEnd }</td>
								</tr>
							})
						}
					</tbody>
				</Table>
				<i><strong>Note: </strong>{ DAYSTOSHOW } days of activity</i><br/><br/>
				</>
			}
		</div>
	);
}

export default LogsDataEntry;
